<template>
  <section>
      <div class="content-header">
        <h2>Incorporating resilient learning strategy and skill-building</h2>
      </div>
      <div class="content-wrapper">
        <p>Ever wonder about your students’ foundational learning skills? </p>
        <p>Inefficient or shaky academic skills like time management, note-taking, or studying can significantly impact students’ academic performance and resilience. </p>
        <p>This multi-campus <a href="https://skillsforuniversitysuccess.info.yorku.ca/files/2019/04/04-26-2019-AcademicSkills.pdf" target="_blank">investigation, report, and call to action</a> indicates high levels of “deficient” learning skills across campuses. </p>
        <p>To bolster your students’ learning skills, with tips on things like, how to prioritize, reading comprehension, remembering material for tests and working collaboratively in groups, explore <a href="https://tia.ryerson.ca/" target="_blank">Thriving in Action Online</a>.</p>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
